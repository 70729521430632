<template>
  <default>
    <!-- Breadcrumb Navigation -->
    <div class="tw-mb-4 c-blue-text">
      <v-chip-group mandatory active-class="primary">
        <v-chip active-class="false" :to="{ name: 'Dashboard' }">Dashboard</v-chip>
        <span class="mt-2 mx-2"> > </span>
        <v-chip :to="{ name: 'Products' }">Products</v-chip>
        <span class="mt-2 mx-2"> > </span>
        <v-chip :to="{ name: 'AgentGuide' }">{{ $options.name }}</v-chip>
      </v-chip-group>
    </div>

    <!-- Main Card with Animated Title -->
    <v-card elevation="2"
      class="main-card tw-px-4 md:tw-px-8 tw-mt-5 md:tw-mt-12 tw-rounded-lg tw-shadow-lg tw-bg-gray-100">
      <!-- Card Title with Icon -->
       <div class="animated-title tw-mt-5 tw-mx-5">
        <card-title :icon="scanObject(currentProduct, 'icon', '/images/icons/elimu.png')">
        {{ scanObject(currentProduct, 'title') }}
      </card-title>
       </div>
      <!-- Back Navigation -->
      <back-nav :to="backNavLink" :page-change="true" />

      <!-- Agent Information -->
      <div class="container tw-w-full tw-py-6 md:tw-py-10 tw-text-center">
        <p class="md:tw-w-2/3 tw-mx-auto tw-text-base md:tw-text-lg tw-text-gray-700">
          {{ agentInfo }}
        </p>
      </div>

      <!-- Instructions Section with Heading and Animated Borders -->
      <h3 class="tw-text-center tw-font-semibold tw-text-lg md:tw-text-xl tw-text-blue-800 tw-mb-4">Requirements for
        Onboarding</h3>
      <div class="tw-grid md:tw-grid-cols-3 tw-gap-8 tw-py-5">
        <v-card v-for="(instruction, index) in instructions" :key="index" elevation="1"
          class="instruction-card tw-rounded-md tw-shadow-md tw-bg-white tw-py-6 tw-px-8 tw-flex tw-flex-col tw-items-center tw-transition-transform tw-transform hover:tw-scale-105">
          <img :src="instruction.image" alt="icon" class="tw-w-20 tw-h-20 tw-mb-4" />
          <p class="tw-text-center tw-font-semibold tw-text-blue-600 tw-pt-2">
            {{ instruction.details }}
          </p>
        </v-card>
      </div>

      <!-- Proceed Button with Icon -->
      <div class="tw-flex tw-justify-center tw-my-8">
        <v-btn id="buyCover"
          class="secondary tw-w-64 tw-font-semibold tw-text-lg tw-py-4 tw-rounded-md tw-shadow-lg tw-transform hover:tw-scale-105"
          large :loading="loading" @click="loading = true" :to="{ name: 'BuyCover' }">
          <v-icon left>mdi-arrow-right</v-icon>
          Proceed
        </v-btn>
      </div>
    </v-card>
  </default>
</template>

<script>
import Default from '../../layouts/Default';
import CardTitle from '../../components/CardTitle';
import clientInfoMixins from '@/mixins/clientInfoMixins';
import BackNav from '@/components/BackNav';

export default {
  name: 'AgentGuide',
  mixins: [clientInfoMixins],
  metaInfo: {
    title: 'Buy Cover - Agent Guide',
  },
  components: {
    Default,
    BackNav,
    CardTitle,
  },
  computed: {
    backNavLink() {
      return this.scanObject(this.currentProduct, 'title', '').replace(/\s/g, '');
    },
  },
  data() {
    return {
      loading: false,
      agentInfo: 'As an Agent there are a few things we require from the client that you need to provide  to get them onboarded',
      instructions: [
        {
          image: '/images/icons/client.png',
          details: 'Client details',
        },
        {
          image: '/images/icons/beneficiaries.png',
          details: 'Beneficiary details',
        },
        {
          image: '/images/icons/health.png',
          details: 'Health questions & declaration',
        },
      ],
    };
  },
  created() {
    if (typeof window !== 'undefined' && window.gtag) {
      window.gtag('event', 'agent_guide_page_visited_by_agent', {
        event_category: 'agent guide component created',
        event_label: 'agent guide component created',
      });
    }
  },
  mounted() {
    this.$store.commit('quote/resetSaved', false);
  },
};
</script>

<style scoped>
.main-card {
  background-color: #f5f5f5;
}

.icon-align {
  width: 32px;
  height: 32px;
}

.animated-title {
  animation: fadeIn 3s ease-in-out infinite;
  animation-delay: 3s; /* Delay before each repeat */
}

@keyframes fadeIn {
  0%, 100% {
    opacity: 0;
    transform: translateY(6px);
  }
  50% {
    opacity: 1;
    transform: translateY(0);
  }
}

.instruction-card {
  transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease;
  border: 2px solid transparent;
}

.instruction-card:hover {
  border-color: #007bff;
  /* Blue border on hover */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  /* Slight shadow on hover */
}
</style>
